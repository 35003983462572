<template>
  <div :class="$style.home">
    <div :class="$style.base">
      <p :class="$style.title">基础信息</p>
      <div :class="$style.img">
        <div :class="$style.imgbox">
          <img v-if="data.base" :src="data.base.logo" alt="" />
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">文化云名称</div>
        <div :class="$style.right">
          <span>{{ data.base.name }}</span>
          <BjLink v-permission="'system.account.platform-edit'" type="primary" class="pull-right" @click="onEditName()"
            >设置</BjLink
          >
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">文化云类型</div>
        <div :class="$style.right">
          <span>{{ data.base.type_name }}</span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">机构名称</div>
        <div :class="$style.right">
          <span
            >{{ data.base.organization_name }}
            <i
              :class="[$style.start, data.base.certificate_status !== 2 ? $style.nop : null]"
              class="ri-shield-star-line"
            />
          </span>
          <BjLink v-permission="'system.account.platform-cert'" type="primary" class="pull-right" @click="onProve()">{{
            btnName
          }}</BjLink>
          <BjTag v-if="stateName" :class="$style.tag" :type="state">
            {{ stateName }}
            <a-tooltip>
              <template slot="title"> {{ data.base.certificate_message }} </template>
              <i v-if="data.base.certificate_status === 3" class="ri-question-line" />
            </a-tooltip>
          </BjTag>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">机构类型</div>
        <div :class="$style.right">
          <span>{{ data.base.organization_type_name }}</span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">所在地区</div>
        <div :class="$style.right">
          <span>{{ data.base.location }}</span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">联系姓名</div>
        <div :class="$style.right">
          <span>{{ data.base.contacts }}</span>
          <BjLink v-permission="'system.account.platform-edit'" type="primary" class="pull-right" @click="onEditInfo()"
            >设置</BjLink
          >
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">联系电话</div>
        <div :class="$style.right">
          <span>{{ data.base.contacts_tel }}</span>
        </div>
      </div>
    </div>

    <div :class="$style.info">
      <p :class="$style.title">注册信息</p>
      <div :class="$style.box">
        <div :class="$style.left">管理员账号</div>
        <div :class="$style.right">
          <span>{{ data.register.account }}</span>
          <BjLink
            v-permission="'system.account.platform-edit'"
            type="primary"
            class="pull-right"
            @click="onEditManage()"
            >更换</BjLink
          >
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">APPID</div>
        <div :class="$style.right">
          <span id="copy">{{ data.register.app_id }}</span>
          <BjLink type="primary" class="pull-right" @click="onCopy()">复制</BjLink>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">注册时间</div>
        <div :class="$style.right">
          <span>{{ data.register.created_at }}</span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">最后登录</div>
        <div :class="$style.right">
          <span>{{ data.register.last_operate }}</span>
        </div>
      </div>
    </div>

    <div :class="$style.info">
      <p :class="$style.title">高级信息</p>
      <div :class="$style.box">
        <div :class="$style.left">文化云版本</div>
        <div :class="$style.right">
          <span>
            {{ data.advanced.version_name }}
            <span class="tag ml-10">到期时间: {{ data.advanced.expire_date }}</span>
          </span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">文化云访问</div>
        <div :class="$style.right">
          <span
            >已开通
            <span class="primary">{{ data.advanced.terminal_num }}</span>
            个前端
          </span>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">个性化域名</div>
        <div :class="$style.right">
          <p v-if="data.advanced.h5_link">移动网页端：{{ data.advanced.h5_link }}</p>
          <p v-if="data.advanced.web_link">桌面网页端：{{ data.advanced.web_link }}</p>
        </div>
      </div>
    </div>

    <div :class="$style.info">
      <p :class="$style.title">协议信息</p>
      <!-- <a-row>
        <a-col span="4">平台介绍</a-col>
        <a-col span="20">
          <span>{{ data.agreement.describe ? '已完善' : '未完善' }}</span>
          <BjLink type="primary" class="pull-right" @click="pageTo('systemPlatformIntroduce')">
            {{ data.agreement.describe ? '去修改' : '去完善' }}
          </BjLink>
        </a-col>
      </a-row> -->
      <div :class="$style.box">
        <div :class="$style.left">平台介绍</div>
        <div :class="$style.right">
          <span>{{ data.agreement.describe ? '已完善' : '未完善' }}</span>
          <BjLink
            v-permission="'system.account.platform-edit'"
            type="primary"
            class="pull-right"
            @click="pageTo('systemPlatformIntroduce')"
          >
            {{ data.agreement.describe ? '去修改' : '去完善' }}
          </BjLink>
        </div>
      </div>
      <!-- <a-row>
        <a-col span="4">用户协议</a-col>
        <a-col span="20">
          <span>{{ data.agreement.eula ? '已完善' : '未完善' }}</span>
          <BjLink type="primary" class="pull-right" @click="pageTo('systemPlatformEula')">
            {{ data.agreement.eula ? '去修改' : '去完善' }}
          </BjLink>
        </a-col>
      </a-row> -->
      <div :class="$style.box">
        <div :class="$style.left">用户服务协议</div>
        <div :class="$style.right">
          <span>{{ data.agreement.eula ? '已完善' : '未完善' }}</span>
          <BjLink
            v-permission="'system.account.platform-edit'"
            type="primary"
            class="pull-right"
            @click="pageTo('systemPlatformEula')"
          >
            {{ data.agreement.eula ? '去修改' : '去完善' }}
          </BjLink>
        </div>
      </div>
      <!-- <a-row>
        <a-col span="4">隐私保护说明</a-col>
        <a-col span="20">
          <span>{{ data.agreement.privacy ? '已完善' : '未完善' }}</span>
          <BjLink type="primary" class="pull-right" @click="pageTo('systemPlatformPrivacy')">
            {{ data.agreement.privacy ? '去修改' : '去完善' }}
          </BjLink>
        </a-col>
      </a-row> -->
      <div :class="$style.box">
        <div :class="$style.left">用户隐私保护政策</div>
        <div :class="$style.right">
          <span>{{ data.agreement.privacy ? '已完善' : '未完善' }}</span>
          <BjLink
            v-permission="'system.account.platform-edit'"
            type="primary"
            class="pull-right"
            @click="pageTo('systemPlatformPrivacy')"
          >
            {{ data.agreement.privacy ? '去修改' : '去完善' }}
          </BjLink>
        </div>
      </div>
    </div>
    <validateModal :visible.sync="visible.validate" :params="params" @submit="validate" />
    <validateModal :visible.sync="visible.attestation" :params="attestation" @submit="attestationSubmit" />
    <bj-modal
      title="设置名称和类型"
      :visible="visible.name"
      :body-style="{ height: '400px' }"
      :class="$style.modal"
      @ok="nameOk"
      @cancel="nameCancel"
    >
      <p :class="$style.tips">
        文化云名称设置需要符合当前使用文化云的场景，比如地区、主题等，文化云名称在前端会向用户进行展示；文化云类型请根据实际选择。
      </p>
      <BjForm>
        <a-row>
          <a-col :class="$style.stitle" span="6">
            <span class="required">*</span>
            文化云头像
          </a-col>
          <a-col span="18">
            <uploadAvatar :src="modalName.logo" :round="true" @complete="avatarChange" />
          </a-col>
        </a-row>
        <BjInput
          v-model="modalName.name"
          class="mt-20"
          v-bind="layout"
          rules="required"
          label="文化云名称"
          label-align="left"
          tooltips
          placeholder="请输入文化云名称"
        />
        <BjSelect
          v-model="modalName.type"
          v-bind="layout"
          label-align="left"
          rules="required"
          tooltips
          key-field="value"
          label="文化文类型"
          placeholder="请选择文化文类型"
          value-field="id"
          label-field="name"
          show-all
          :options="typeData"
        />
      </BjForm>
    </bj-modal>

    <bj-modal
      title="更换管理员"
      :visible="visible.manage"
      :body-style="{ height: '400px' }"
      :class="$style.modal"
      @ok="manageOk"
      @cancel="manageCancel"
    >
      <BjForm>
        <BjSelect
          v-model="modalManage.id"
          v-bind="layout"
          label-align="left"
          rules="required"
          tooltips
          key-field="value"
          label="选择管理员"
          placeholder="请从子账号中选择要成为管理员的账号"
          value-field="id"
          label-field="real_name"
          show-all
          :options="accountData"
        />
        <a-row>
          <a-col span="6" />
          <a-col span="18" class="tip">确认更换之后，当前的管理员的权限将和更换后的账号进行权限互换。</a-col>
        </a-row>
      </BjForm>
    </bj-modal>

    <bj-modal
      title="联系信息"
      :visible="visible.info"
      :body-style="{ height: '400px' }"
      :class="$style.modal"
      @ok="infoOk"
      @cancel="infoCancel"
    >
      <BjForm>
        <BjInput
          v-model="modalInfo.contacts"
          v-bind="layout"
          rules="required"
          label="联系名称"
          label-align="left"
          tooltips
          placeholder="请输入联系名称"
        />
        <BjInput
          v-model="modalInfo.contacts_tel"
          v-bind="layout"
          rules="required"
          label="联系电话"
          label-align="left"
          tooltips
          placeholder="请输入联系电话"
        />
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import uploadAvatar from '@/components/uploadAvatar'
import validateModal from '@/components/validateModal'
import { platformService, systemService } from '@/service'

const service = new platformService()
const sService = new systemService()

export default {
  name: 'Home',
  components: {
    validateModal,
    uploadAvatar,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      data: {
        base: {},
        register: {},
        agreement: {},
      },
      visible: {
        validate: false,
        name: false,
        manage: false,
        info: false,
        attestation: false,
      },
      modalName: {
        logo: null,
        name: null,
        type: null,
      },
      modalInfo: {
        contacts: null,
        contacts_tel: null,
      },
      modalManage: {
        id: null,
      },
      accountData: [],
      params: {},
      valid_key: null,
      typeData: [],
      attestation: {},
    }
  },
  computed: {
    state() {
      if (this.data.base.certificate_status === 1) {
        return 'default'
      }
      if (this.data.base.certificate_status === 3) {
        return 'danger'
      }
      return ''
    },
    stateName() {
      if (this.data.base.certificate_status === 1) {
        return '审核中'
      }
      if (this.data.base.certificate_status === 3) {
        return '审核驳回'
      }
      return false
    },
    btnName() {
      if (this.data.base.certificate_status === 0) {
        return '去认证'
      }
      if (this.data.base.certificate_status === 1) {
        return '修改认证'
      }
      if (this.data.base.certificate_status === 2) {
        return '查看认证信息'
      }
      if (this.data.base.certificate_status === 3) {
        return '修改认证'
      }
      return ''
    },
  },
  created() {
    this.getInfo()
    this.getType()
  },
  methods: {
    ...mapActions(['refreshToken']),
    async getInfo() {
      const { data } = await service.getInfo()
      this.data = data
    },
    async getType() {
      const { data } = await service.cloudType()
      this.typeData = data
    },
    onCopy() {
      var Url2 = document.getElementById('copy').innerText
      var oInput = document.createElement('input')
      oInput.value = Url2
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message.success('复制成功')
      oInput.parentNode.removeChild(oInput) // 删除
    },
    onEditName() {
      this.visible.validate = true
      this.params = {
        type: 'name',
      }
      this.modalName = {
        logo: this.data.base.logo,
        name: this.data.base.name,
        type: this.data.base.type,
      }
    },
    async nameOk() {
      try {
        await service.saveName({
          ...this.modalName,
          valid_key: this.valid_key,
        })
        this.$message.success('保存成功')
        this.nameCancel()
        this.getInfo()
        this.refreshToken()
      } catch (e) {}
    },
    nameCancel() {
      this.visible.name = false
    },
    avatarChange(e) {
      this.modalName.logo = e
    },
    validate(e) {
      if (e.type) {
        this.visible[e.type] = true
        this.valid_key = e.valid_key
      }
    },
    attestationSubmit(e) {
      this.valid_key = e.valid_key
      this.onProve()
    },
    onEditManage() {
      this.visible.validate = true
      this.params = {
        type: 'manage',
      }
      this.modalManage = {
        id: this.data.register.id,
      }
      // this.visible.manage = true
      this.getAccount()
    },
    onEditInfo() {
      this.visible.info = true
      this.modalInfo = {
        contacts: this.data.base.contacts,
        contacts_tel: this.data.base.contacts_tel,
      }
    },
    async manageOk() {
      try {
        await service.saveManage({
          ...this.modalManage,
          valid_key: this.valid_key,
        })
        this.$message.success('保存成功')
        this.nameCancel()
      } catch (e) {}
    },
    manageCancel() {
      this.visible.manage = false
    },
    async getAccount() {
      const { data } = await sService.accountList({
        no_page: 1,
      })
      this.accountData = data
    },
    async infoOk() {
      try {
        await service.saveInfo(this.modalInfo)
        this.$message.success('保存成功')
        this.infoCancel()
        this.getInfo()
      } catch (e) {}
    },
    infoCancel() {
      this.visible.info = false
    },
    onProve() {
      if (this.data.base.certificate_status === 0) {
        // 没认证
        this.$router.push({
          name: 'systemPlatformCertificate',
          query: {
            type: 'add',
            key: this.valid_key,
          },
        })
      } else if (this.data.base.certificate_status === 1) {
        // 审核中
        this.$router.push({
          name: 'systemPlatformCertificate',
          query: {
            type: 'edit',
            key: this.valid_key,
          },
        })
      } else if (this.data.base.certificate_status === 2) {
        //如果认证了
        this.$router.push({
          name: 'systemPlatformCertificateInfo',
        })
      } else {
        //
      }
    },
    pageTo(_name) {
      this.$router.push({
        name: _name,
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-col {
      height: 40px;
    }

    .ant-col-20 {
      color: #000;
      border-bottom: 1px solid #eee;
    }

    .ant-row {
      margin-bottom: 20px;
      color: #000;
    }
  }

  .title {
    color: #000;
    font-size: 16px;
  }

  .base {
    padding: 20px;
    background: #fff;

    .start {
      margin-left: 10px;
      color: @primary-color;
      font-size: 20px;
      vertical-align: -4px;
    }

    .nop {
      color: #bbb;
    }
  }

  .info {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }

  .img {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: 20px;

    .imgbox {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 100%;
      transform: translate(-50%, -50%);

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }
  }
}

.box {
  display: flex;
  height: 42px;
  margin-bottom: 20px;

  .left {
    width: 180px;
    color: #5c5c5c;
  }

  .right {
    flex: 1;
    border-bottom: 1px solid #eee;

    .tag {
      float: right;
      margin-right: 20px;
      color: #5c5c5c;

      i {
        margin-left: 4px;
        color: #5c5c5c;
        font-size: 18px;
      }
    }
  }
}

.modal {
  .tips {
    padding: 10px;
    color: #5c5c5c;
    font-size: 12px;
    background: #fafafa;
  }

  .stitle {
    color: #000;
    font-size: 14px;
  }
}
</style>
